import * as signalR from "@microsoft/signalr";

let connection: signalR.HubConnection | null;

export const connect = async (userid: string) => {
  if (connection != null && connection.state === "Connected") {
    await connection.stop();
    connection = null;
  }

  connection = new signalR.HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_SERVICE_URL}/hubchats?userid=${userid}`)
    .withAutomaticReconnect()
    .build();

  return connection.start().then(() => ({ connection }));
};
