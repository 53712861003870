import { Row, Col, Input, Grid, Button } from "antd";
import { useState } from "react";
import { sendMessage } from "../../services/newsService";
import { isEmpty } from "lodash";

const { useBreakpoint } = Grid;
const { TextArea } = Input;

const TextEditor = ({ conversationId, scrollDownBoard }) => {
  const screens = useBreakpoint();
  const [content, setContent] = useState();
  const { xs, sm, md } = screens;
  const isMobile = xs || (sm && !md);
  const isOnlyMobile = xs;

  const handlerSubmit = () => {
    sendMessage(conversationId, content);
    setContent("");
    scrollDownBoard();
  };

  const onEnter = (e) => {
    const hasContent = !isEmpty(content);
    if (e.keyCode === 13 && e.ctrlKey && hasContent) handlerSubmit();
  };

  const handlerChange = (e) => {
    const { value } = e.target;
    setContent(value);
  };

  const rowStyle = isOnlyMobile ? "text-editor-position" : "";

  return (
    <Row className={rowStyle}>
      <Col span={24}>
        <TextArea
          className="boradius-10"
          rows={4}
          showCount={!isMobile}
          allowClear
          value={content}
          onChange={handlerChange}
          onKeyDown={onEnter}
        />
        {!isMobile && (
          <p className="f-mono">Please press Ctrl + Enter to send...</p>
        )}
      </Col>
      {isMobile && (
        <Col className="txt-right" span={24}>
          <Button
            type="default"
            size="middle"
            className="main-color"
            onClick={handlerSubmit}
          >
            Send
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default TextEditor;
