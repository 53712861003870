import { Input, Typography } from "antd";
import { useField } from "formik";

const { Text } = Typography;

const TextField = ({ name, label, type, ...props }) => {
  const [field, meta] = useField(name);
  return (
    <div>
      <Input {...props} name={name} label={label} type={type} {...field} />
      {meta.touched && meta.error ? (
        <Text type="danger">{meta.error}</Text>
      ) : null}
    </div>
  );
};

export default TextField;
