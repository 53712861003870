import { Col, Row, Divider } from "antd";
import UserInfor from "../user-infor/user-infor";
import CollapseSideBar from './collapse';

function ChatGroup() {
  return (
    <>
      <Row justify="center">
        <Col span={22} className="m-t-b-15">
          <UserInfor />
        </Col>
        <Divider></Divider>
        <Col span={24}>
          <CollapseSideBar />
        </Col>
      </Row>
    </>
  );
}

export default ChatGroup;
