import { useState, useEffect } from "react";
import { useGlobalLotteryContext } from "../../../hooks/useGlobalLotteryContext.ts";
import { format } from "date-fns";
import { countryWeekDic, countryDic } from "../../../helpers/countryHelper";
import { Typography, Col, Row, Form, Button } from "antd";
import CountryGrid from "../country/countryGrid.jsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import BaseFormik from "../../../componentsUltils/Formik/BaseFormik.jsx";
import NumberField from "../../../componentsUltils/Field/NumberField.jsx";
import { useTranslation } from "react-i18next";
import { checkLottery } from "../../../services/lotteryService.js";
import ThanTaiImg from "../../../assets/images/thantai.webp";

const { Title, Text } = Typography;

const LotteryCheckSection = () => {
  const { t } = useTranslation();
  const [awards, setAwards] = useState();
  const [countries, setCountries] = useState();
  const { cursorLotteryDashboard, setCursorLotteryDashboard } =
    useGlobalLotteryContext();

  const validationSchema = Yup.object().shape({
    ticketNumber: Yup.string()
      .required("Required")
      .typeError("Invalid")
      .length(6, t("lottery_page.mgsErrorTicketNumber", { val: 6 }))
      .when("countryNormalize", (values, schema) => {
        const countrySelected = values[0];
        return digitalLottery.includes(countrySelected)
          ? schema.length(
            12,
            t("lottery_page.mgsErrorTicketNumber", { val: 12 })
          )
          : schema;
      }),
    countryNormalize: Yup.string().required("Required"),
  });

  useEffect(() => {
    const { date } = cursorLotteryDashboard;
    const dayOfWeek = format(date, "EEEE");
    const countriesKey = countryWeekDic[dayOfWeek];
    const { countrySelected } = cursorLotteryDashboard;
    const countries = countriesKey.map((s) => ({
      ...countryDic[s],
      isActive: s === countrySelected,
      keyName: s,
    }));
    setCountries(countries);
    setAwards(undefined);
  }, [cursorLotteryDashboard]);

  const onHandleCountrySelect = (key) => {
    const newCountries = countries.map((s) => ({
      ...s,
      isActive: s.keyName === key,
    }));
    setCountries(newCountries);
    setCursorLotteryDashboard({
      ...cursorLotteryDashboard,
      countrySelected: key,
    });
    setAwards(undefined);
  };

  const formik = useFormik({
    initialValues: {
      date: cursorLotteryDashboard.date,
      ticketNumber: null,
      countryNormalize: cursorLotteryDashboard.countrySelected,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      checkLottery({ ...values })
        .then((res) => {
          const { data } = res;
          setAwards(data);
        })
        .finally(_ => {
          formik.setSubmitting(false);
        });
    },
  });

  const onSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <BaseFormik formik={formik}>
      <div className="bg-white">
        <CountryGrid
          items={countries}
          onCountrySelect={onHandleCountrySelect}
        />
        <Row className="bg-white m-b-20">
          <Row className="p-l-20 m-t-20">
            <Col xs={12} sm={12} md={12} lg={12} className="m-r-20">
              <Form.Item
                label={t("lottery_page.lookUpAwards")}
                name="ticketNumber"
              >
                <NumberField
                  placeholder={t("lottery_page.lookUpAwards")}
                  label={t("lottery_page.ticketNumber")}
                  id="ticketNumber"
                  name="ticketNumber"
                />
                <Button onClick={onSubmit} disabled={formik.isSubmitting}>
                  {t("lottery_page.btnSearch")}
                </Button>
              </Form.Item>
            </Col>
            {awards &&
              (awards.isWon ? (
                <Col xs={12} sm={12} md={12} lg={11}>
                  <Title level={4}>{awards.content}</Title>
                  <Typography>
                    {t("lottery_page.theMatch")}: &ensp;
                    <Text type="danger">
                      <strong>{awards.lotteriesTextMatch.join(",")}</strong>
                    </Text>
                  </Typography>
                  <img src={ThanTaiImg} alt="thantai" width={75} />
                </Col>
              ) : (
                <Col xs={12} sm={12} md={12} lg={11}>
                  <Title level={4}>{awards.content}</Title>
                  <Text type="warning">
                    Có thắng có thua vô thường người ơi!
                  </Text>
                </Col>
              ))}
          </Row>
        </Row>
      </div>
    </BaseFormik>
  );
};

const digitalLottery = ["mega", "power"];

export default LotteryCheckSection;
