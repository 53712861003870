import { useCallback, useEffect, useState } from "react";
import useDialog from "../../../../hooks/useDialog";
import UserTicketDialog from "./userTicketDialog";
import { Row, Col, Input } from "antd";
import { useGlobalLotteryContext } from "../../../../hooks/useGlobalLotteryContext.ts";
import {
  createUserTicket,
  deleteUserTicket,
} from "../../../../services/userTicketService.js";
import LotteryGrid from "../../lottery/lotteryGrid";
import { getUserTicketsByCountry } from "../../../../services/userTicketService.js";
import { PlusCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
import { debounce, filter, isEmpty } from "lodash";
import { countryDic } from "../../../../helpers/countryHelper.js";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Search } = Input;

const UserTicketSection = () => {
  const { t } = useTranslation();
  const { show, confirm } = useDialog();
  const [txtSearch, setTxtSearch] = useState();
  const [tickets, setTickets] = useState([]);
  const [searchTickets, setSearchTickets] = useState([]);
  const { cursorUserTicketManage } = useGlobalLotteryContext();

  const onHandleClick = async () => {
    const { date, countrySelected } = cursorUserTicketManage;
    const response = await show(UserTicketDialog, {
      quantity: 1,
      date: date,
      country: countrySelected,
    });
    const { isSuccess, data } = response;

    if (!isSuccess) return;

    const res = await createUserTicket(data);
    const hasUpdateRecord =
      tickets.filter((s) => s.id === res.data.id).length !== 0;
    let filterTickets = tickets;
    if (hasUpdateRecord)
      filterTickets = tickets.filter((s) => s.id !== res.data.id);

    setTickets([...filterTickets, res.data]);
    if (isEmpty(txtSearch)) setSearchTickets([...filterTickets, res.data]);
  };

  useEffect(() => {
    const { date, countrySelected } = cursorUserTicketManage;
    if (!date || !countrySelected) return;

    async function init() {
      const res = await getUserTicketsByCountry(date, countrySelected);
      const {
        data: { data },
      } = res;
      setTickets(data);
      setSearchTickets(data);
    }
    Promise.all([init()]);
  }, [cursorUserTicketManage]);

  const onSearch = debounce((txt, evt) => {
    setTxtSearch(txt);
  }, 500);

  const filterTickets = useCallback(() => {
    if (!txtSearch) return tickets;
    const newTickets = filter(tickets, function (s) {
      return s.ticketNumber.includes(txtSearch);
    });
    return newTickets;
    // eslint-disable-next-line
  }, [txtSearch]);

  useEffect(() => {
    const newTickets = filterTickets();
    setSearchTickets(newTickets);
    // eslint-disable-next-line
  }, [txtSearch]);

  const onHandlerDelete = async ({ id, country, ticketNumberLabel }) => {
    const response = await confirm({
      title: "Do you want delete it?",
      message: (
        <p>
          Remove lottery: <strong>{ticketNumberLabel}</strong> on country{" "}
          {countryDic[country].name}
        </p>
      ),
    });
    const { isSuccess } = response;
    if (!isSuccess) return;
    await deleteUserTicket(id);

    const newTickets = tickets.filter((s) => s.id !== id);
    setTickets(newTickets);
    const newSearchTickets = searchTickets.filter((s) => s.id !== id);
    setSearchTickets(newSearchTickets);
  };

  return (
    <Row className="bg-white p-l-20 mw-100">
      <Col className="m-t-20 flex-space-between" span={24}>
        <Title level={4}>{t("tickets")}</Title>
        <div className="lottery-hint">
          <div className="lottery-card">{t("new")}</div>
          <div className="lottery-card-lose">{t("lose")}</div>
          <div className="lottery-card-won">{t("won")}</div>
        </div>
      </Col>
      <Col className="utik-tools" span={24}>
        <PlusCircleFilled
          style={{ fontSize: "32px", color: "olivedrab" }}
          onClick={onHandleClick}
        />
        &ensp;
        <Search
          placeholder={t("searchTicket")}
          onSearch={onSearch}
          style={{ width: 200 }}
        />
      </Col>
      <LotteryGrid items={searchTickets} deleteEvent={onHandlerDelete} />
    </Row>
  );
};

export default UserTicketSection;
