import { Col } from "antd";
import { Row } from "antd";
import MenuNavigate from "../../../../componentsUltils/menudate/MenuNavigate";
import BaseFormik from "../../../../componentsUltils/Formik/BaseFormik";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { countryWeekDic, countryDic } from "../../../../helpers/countryHelper";
import CountryGrid from "../../country/countryGrid";
import { useGlobalLotteryContext } from "../../../../hooks/useGlobalLotteryContext.ts";
import UserTicketSection from "./userTicketSection";
import { useTranslation } from "react-i18next";

const ManageUserTicket = () => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState();
  const { cursorUserTicketManage, setCursorUserTicketManage } =
    useGlobalLotteryContext();

  const formik = useFormik({
    initialValues: {
      date: cursorUserTicketManage.date,
    },
    validationSchema,
  });

  useEffect(() => {
    if (formik.values.date === null) return;
    const dayOfWeek = format(formik.values.date, "EEEE");
    const countriesKey = countryWeekDic[dayOfWeek];
    const { countrySelected } = cursorUserTicketManage;
    const countries = countriesKey.map((s) => ({
      ...countryDic[s],
      isActive: s === countrySelected,
      keyName: s,
    }));
    setCountries(countries);
    setCursorUserTicketManage({
      ...cursorUserTicketManage,
      date: formik.values.date,
    });
    // eslint-disable-next-line
  }, [formik.values.date]);

  const onHandleCountrySelect = (key) => {
    const newCountries = countries.map((s) => ({
      ...s,
      isActive: s.keyName === key,
    }));
    setCountries(newCountries);
    setCursorUserTicketManage({
      ...cursorUserTicketManage,
      countrySelected: key,
    });
  };

  return (
    <BaseFormik formik={formik}>
      <Row>
        <Col span={24}>
          <MenuNavigate
            title={t("userticket_page.title")}
            id="date"
            name="date"
          />
        </Col>
      </Row>
      <CountryGrid items={countries} onCountrySelect={onHandleCountrySelect} />
      <UserTicketSection />
    </BaseFormik>
  );
};

const validationSchema = Yup.object().shape({
  date: Yup.date().typeError("Invalid").required("Date not empty"),
});

export default ManageUserTicket;
