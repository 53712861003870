import { useFormik } from "formik";
import BaseFormik from "../../../componentsUltils/Formik/BaseFormik";
import { Row, Col } from "antd";
import MenuNavigate from "../../../componentsUltils/menudate/MenuNavigate";
import * as Yup from "yup";
import { useEffect } from "react";
import { useGlobalLotteryContext } from "../../../hooks/useGlobalLotteryContext.ts";
import LotterySection from "./lotterySection.jsx";
import { format } from "date-fns";
import { countryWeekDic, countryDic } from "../../../helpers/countryHelper.js";
import LotteryCheckSection from "./lotteryCheckSection.jsx";

const DashBoardLottery = () => {
  const { cursorLotteryDashboard, setCursorLotteryDashboard } =
    useGlobalLotteryContext();
  const formik = useFormik({
    initialValues: {
      date: cursorLotteryDashboard.date,
    },
    validationSchema,
  });

  useEffect(() => {
    if (formik.values.date === null) return;
    const dayOfWeek = format(formik.values.date, "EEEE");
    const countriesKey = countryWeekDic[dayOfWeek];
    const { countrySelected } = cursorLotteryDashboard;
    const countries = countriesKey.map((s) => ({
      ...countryDic[s],
      isActive: s === countrySelected,
      keyName: s,
    }));
    setCursorLotteryDashboard({
      ...cursorLotteryDashboard,
      date: formik.values.date,
    });
  }, [formik.values.date]);

  return (
    <BaseFormik formik={formik}>
      <Row>
        <Col span={24}>
          <MenuNavigate title="Dashboard" id="date" name="date" />
        </Col>
      </Row>
      <LotteryCheckSection />
      <LotterySection />
    </BaseFormik>
  );
};

const validationSchema = Yup.object().shape({
  date: Yup.date().typeError("Invalid").required("Date not empty"),
});

export default DashBoardLottery;
