import { useGlobalContext } from "./useGlobalContext.ts";
import ConfirmDialog from "../componentsUltils/modals/confirmDialog.jsx";

const useDialog = () => {
  const { dialog, setDialog } = useGlobalContext();

  const show = (component, data) => {
    return new Promise((resolve) => {
      const id = new Date().getTime();
      const onDialogResult = (innerProps) => {
        resolve({
          ...innerProps,
        });
      };
      const props = { onDialogResult, data };
      setDialog({ id, component, props });
    });
  };

  const confirm = ({ title, message }) => {
    return new Promise((resolve) => {
      const id = new Date().getTime();
      const onDialogResult = (innerProps) => {
        resolve({
          ...innerProps,
        });
      };
      const props = {
        onDialogResult,
        children: message,
        title,
      };
      setDialog({ id, component: ConfirmDialog, props });
    });
  };

  return { dialog, show, confirm };
};

export default useDialog;
