import { Collapse } from "antd";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const CollapseLottery = () => {
  const { t } = useTranslation();
  return (
    <Collapse defaultActiveKey={["1", "2", "3"]} ghost>
      <Panel
        header={
          <h3>
            <strong>{t("app")}</strong>
          </h3>
        }
        key="1"
      >
        <AppCollapse translator={t} />
      </Panel>
      <Panel
        header={
          <h3>
            <strong>{t("userticket")}</strong>
          </h3>
        }
        key="2"
      >
        <UserTicketCollapse translator={t} />
      </Panel>
      <Panel
        header={
          <h3>
            <strong>{t("lottery")}</strong>
          </h3>
        }
        key="3"
      >
        <LotteryCollapse translator={t} />
      </Panel>
    </Collapse>
  );
};

const AppCollapse = ({ translator }) => {
  return (
    <ul type="none">
      <li className="p-b-15">
        <Link className="text-none btn-link" to={routes.main.home} replace>
          {translator("chat")}
        </Link>
      </li>
    </ul>
  );
};

const UserTicketCollapse = ({ translator }) => {
  return (
    <ul type="none">
      <li className="p-b-15">
        <Link className="text-none btn-link" to={routes.app.lottery.userTicket}>
          {translator("dashboard")}
        </Link>
      </li>
      <li className="p-b-15">
        <Link
          className="text-none btn-link"
          to={`${routes.app.lottery.userTicket}/manage`}
        >
          {translator("manage")}
        </Link>
      </li>
      <li className="p-b-15">
        <Link
          className="text-none btn-link"
          to={`${routes.app.lottery.userTicket}/grid`}
        >
          {translator("grid")}
        </Link>
      </li>
    </ul>
  );
};

const LotteryCollapse = ({ translator }) => {
  return (
    <ul type="none">
      <li className="p-b-15">
        <Link className="text-none btn-link" to={routes.app.lottery.lottery}>
          {translator("dashboard")}
        </Link>
      </li>
      {/* <li className="p-b-15">
        <Link
          className="text-none btn-link"
          to={`${routes.app.lottery.lottery}/manage`}
        >
          {translator("manage")}
        </Link>
      </li> */}
    </ul>
  );
};

export default CollapseLottery;
