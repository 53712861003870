import { FormikProvider } from "formik";
import React from "react";

const BaseFormik = ({ children, formik, ...rest }) => {
  return (
    <FormikProvider value={formik} {...rest}>
      {children}
    </FormikProvider>
  );
};

export default BaseFormik;
