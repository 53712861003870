import { Card, Row, Col } from "antd";
import { isDate, format } from "date-fns";
import { countryDic } from "../../../../helpers/countryHelper";

const firstCol = {
  marginRight: "10px",
};

const secondCol = {
  marginRight: "10px",
};

const lastCol = {
  marginLeft: 20,
  fontSize: "0.95em",
};

const UserTicketCard = ({ item }) => {
  const statusUserTicket =
    item.ticketResult === null
      ? "new"
      : item.ticketResult?.isWon
      ? "won"
      : "lose";
  const styleCard = `status-${statusUserTicket}`;

  const country = countryDic[item.country];
  return (
    <Card
      className={styleCard}
      style={{ margin: "10px auto 0 auto" }}
      loading={!item}
    >
      <Row>
        <Col style={firstCol}>
          <img
            className="utik-avatar-card"
            alt="country"
            src={country.imageSrc}
          />
        </Col>
        <Col style={secondCol}>
          <TitleCard country={country.name} />
          <NumberCard ticketNumber={item.ticketNumber} />
        </Col>
        <Col style={lastCol}>
          <DateCard date={new Date(item.date)} />
        </Col>
      </Row>
    </Card>
  );
};

const TitleCard = ({ country }) => {
  return (
    <div>
      <h1 className="m-0">{country}</h1>
    </div>
  );
};

const NumberCard = ({ ticketNumber }) => {
  return (
    <p className="m-0 utik-content-card color-number-lottery">{ticketNumber}</p>
  );
};

const DateCard = ({ date }) => {
  return (
    <div>
      <p className="vertical-text txt-right txt-bold f-dancing-bold">
        {isDate(date) && format(date, "MM  dd  yyyy")}
      </p>
    </div>
  );
};

export default UserTicketCard;
