import { Typography } from "antd";
import { isDigitalLottery } from "../../../helpers/lotteryHelper";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const LotteryCard = ({
  id,
  country,
  date,
  quantity,
  ticketNumber,
  ticketResult,
  isDisplayAction,
  deleteEvent,
}) => {
  const { t } = useTranslation();
  const isDigital = isDigitalLottery(country);
  const arrNumber = ticketNumber.match(/.{1,2}/g);
  const ticketNumberLabel = isDigital ? arrNumber.join(" ") : ticketNumber;

  const statusUserTicket =
    ticketResult === null ? "" : ticketResult?.isWon ? "-won" : "-lose";

  const classNameCard = `lottery-card${statusUserTicket}`;

  const onHandleClick = () => {
    deleteEvent({
      id,
      country,
      date,
      quantity,
      ticketNumber,
      ticketNumberLabel,
    });
  };

  const classNameDefault = `${classNameCard} m-r-10 m-b-10 curp ${
    isDisplayAction && "aniwave"
  }`;

  return (
    <div className={classNameDefault}>
      {isDisplayAction && (
        <CloseOutlined
          onClick={onHandleClick}
          style={{
            color: "red",
            fontSize: "32px",
            position: "absolute",
            top: 0,
            right: "10px",
          }}
        />
      )}
      <Typography className="lottery-number">
        <strong>{ticketNumberLabel}</strong>
      </Typography>
      <Typography>
        {t("quantity")}: {quantity}
      </Typography>
    </div>
  );
};

export default LotteryCard;
