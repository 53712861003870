import { Row, Col, Typography } from "antd";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const { Text, Title } = Typography;

const LotteryDigitalTable = ({
  title,
  effectiveDate,
  country,
  document,
  lotteryItems,
}) => {
  const { t } = useTranslation();

  let date = "";
  if (effectiveDate !== undefined)
    date = format(new Date(effectiveDate), "yyyy-MM-dd");

  return (
    <Row>
      <Col span={24} className="m-t-20">
        <Title level={4}>{title}</Title>
        <Text>
          {country} {date}
        </Text>
      </Col>
      <Col>
        <ul type="none" className="dgLty-number">
          {lotteryItems &&
            lotteryItems.map((s) => (
              <li key={s.id}>
                <p>{s.textNumber}</p>
              </li>
            ))}
        </ul>
      </Col>
      {document && (
        <Col>
          <table>
            <thead>
              <tr>
                <th className="p-r-20">{t("lottery_page.awards")}</th>
                <th className="p-r-20">{t("lottery_page.match")}</th>
                <th className="p-r-20">{t("lottery_page.numberOfPrizes")}</th>
                <th>{t("lottery_page.prizeValue")}</th>
              </tr>
            </thead>
            <tbody>
              {document.awards.map((s) => (
                <tr key={s.level}>
                  <td>
                    <Text type="danger">
                      <strong>{s.name}</strong>
                    </Text>
                  </td>
                  <td>{s.length}</td>
                  <td>{t("lottery_page.number", { val: s.count })}</td>
                  <td>{t("lottery_page.number", { val: s.amount })}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      )}
    </Row>
  );
};

export default LotteryDigitalTable;
