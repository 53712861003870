import { useState, useRef, useEffect } from "react";
export default function useLongPress() {
  const [action, setAction] = useState();
  const ref = useRef();
  const timeRef = useRef();
  const isLongPress = useRef(false);

  const startPressTimer = () => {
    isLongPress.current = false;
    timeRef.current = setTimeout(() => {
      setAction("press");
      isLongPress.current = true;
    }, [500]);
  };

  const onHandlerClick = (e) => {
    if (isLongPress.current) {
      return;
    }
    setAction("click");
  };

  const onHandlerTouchStart = () => {
    startPressTimer();
  };
  const onHandlerTouchEnd = () => {
    clearTimeout(timeRef.current);
  };

  const onHandlerMouseUp = () => {
    clearTimeout(timeRef.current);
  };
  const onHandlerMouseDown = () => {
    startPressTimer();
  };

  useEffect(() => {
    const onHandlerClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (action === "outside") return;
        setAction("outside");
        return;
      }
    };

    document.addEventListener("click", onHandlerClickOutside, true);

    return () => {
      document.removeEventListener("click", onHandlerClickOutside, true);
    };
    // eslint-disable-next-line
  }, [ref, isLongPress.current]);

  return {
    ref,
    action,
    handlers: {
      onClick: onHandlerClick,
      onMouseDown: onHandlerMouseDown,
      onMouseUp: onHandlerMouseUp,
      onTouchStart: onHandlerTouchStart,
      onTouchEnd: onHandlerTouchEnd,
    },
  };
}
