import { format } from "date-fns";
import Axios from "./axios";

const MAX_SIZE = 2000000000;
const digitalLottery = ["mega", "power"];

export const checkLottery = ({ date, countryNormalize, ticketNumber }) => {
  const isDigital = digitalLottery.includes(countryNormalize);
  if (isDigital)
    return checkLotteryDigital({ date, countryNormalize, ticketNumber });
  return checkLotteryNormal({ date, countryNormalize, ticketNumber });
};

export const checkLotteryNormal = ({ date, countryNormalize, ticketNumber }) =>
  Axios({
    withCredentials: true,
    url: `/news/lotteries/lottery`,
    method: "GET",
    params: {
      date,
      countryNormalize,
      ticketNumber,
    },
  });

export const checkLotteryDigital = ({ date, countryNormalize, ticketNumber }) =>
  Axios({
    withCredentials: true,
    url: `/news/lotteries/lottery/${countryNormalize}`,
    method: "GET",
    params: {
      date,
      countryNormalize,
      ticketNumber,
    },
  });

export const getLotteryByDate = ({ date }) => {
  const txtDate = format(date, "yyyy-MM-dd");
  const filters = `{"Key":"effectiveDate","Operate":"eq","Value":"${txtDate}"}`;
  return getLotteries({ pageIndex: 1, pageSize: MAX_SIZE, filters: filters });
};

export const getLotteries = ({ pageIndex, pageSize, filters, orders }) =>
  Axios({
    withCredentials: true,
    url: `/news/lotteries`,
    method: "GET",
    params: {
      pageSize,
      pageIndex,
      filters,
      orders,
    },
  });

export const checkLotteries = (items) =>
  Axios({
    withCredentials: true,
    url: `/news/lotteries`,
    method: "GET",
    data: { lottries: items },
  });
