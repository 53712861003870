import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Input, Modal, Space, Typography } from "antd";
import { signIn } from "../../services/userService.js";
import { connect } from "../../services/signalRService.ts";
import { useGlobalContext } from "../../hooks/useGlobalContext.ts";
import BaseFormik from "../../componentsUltils/Formik/BaseFormik.jsx";
import * as Yup from "yup";
import useAuthentication from "../../hooks/useAuthentication.js";

const { Text } = Typography;

const validationSchema = Yup.object().shape({
  username: Yup.string().min(5).required("Required").typeError("Invalid"),
  password: Yup.string().required("Required").min(8).typeError("Invalid"),
});

const Login = () => {
  const [open, setOpen] = useState(true);
  const { setCredential } = useAuthentication();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();
  const { setConnection, connection, setUser } = useGlobalContext();

  const handleOk = ({ username, password }) => {
    signIn(username, password)
      .then((res) => {
        const { data } = res;
        connect(data.id)
          .then(({ connection }) => {
            setConnection(connection);
            setUser(data);
            navigate("/");
            closeModal();
          })
          .catch((error) => {
            setConfirmLoading(false);
          });

        setCredential(data);
      })
      .catch(() => {
        setConfirmLoading(false);
      });
  };

  useEffect(() => {
    if (connection === undefined) {
      setOpen(true);
      return;
    }
    const { state } = connection;
    if (state === "Connected") navigate("/");
  }, [connection]);

  const closeModal = () => {
    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
    navigate("/sign-up");
  };

  const onEnterLogin = (e) => {
    if (e.keyCode === 13) formik.handleSubmit();
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleOk(values);
    },
  });

  return (
    <BaseFormik formik={formik}>
      <Modal
        title="Login"
        open={open}
        onOk={formik.handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Login"
        cancelText="SignUp"
        maskClosable={false}
        closable={false}
      >
        <Space direction="vertical">
          <Input
            id="username"
            name="username"
            placeholder="Username"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            onKeyDown={onEnterLogin}
            prefix={<UserOutlined />}
          />
          {formik.touched.username && formik.errors.username ? (
            <Text type="danger">{formik.errors.username}</Text>
          ) : null}

          <Input
            id="password"
            name="password"
            placeholder="Password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            onKeyDown={onEnterLogin}
            prefix={<UserOutlined />}
          />
          {formik.touched.password && formik.errors.password ? (
            <Text type="danger">{formik.errors.password}</Text>
          ) : null}
        </Space>
      </Modal>
    </BaseFormik>
  );
};

export default Login;
