import { useState } from "react";

const useAuthentication = () => {
  const existAuthen = getCurrentUser() !== null;
  const [isAuthenticate, setIsAuthenticate] = useState(existAuthen);

  const setCredential = (user) => {
    if (user === undefined || user === null) {
      setIsAuthenticate(false);
      localStorage.clear("credential");
      return;
    }
    localStorage.setItem("credential", JSON.stringify(user));
    setIsAuthenticate(true);
  };

  return { isAuthenticate, setCredential, getCurrentUser };
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("credential"));
};

export default useAuthentication;
