import { Col, Row, Spin, Grid } from "antd";
import { useState, useRef, memo } from "react";
import MessageAuthor from "./message-author";
import { getMessagesCursor } from "../../services/newsService";
import { useEffect } from "react";
import { uniqBy } from "lodash";
import { useGlobalContext } from "../../hooks/useGlobalContext.ts";
import { RECEIVE_MESSAGE } from "../../Constants/commonConstant";
import { isEmpty } from "lodash";
import { uuidv4 } from "../../helpers/helper";

const { useBreakpoint } = Grid;

const initPage = {
  next: "",
  previous: "",
  totalMiliseconds: 0,
};
const defaultPageSize = 20;
let defaultConversationId = "";
let isFirstRenderOfConversation = true;
const MessageBoard = ({ conversationId, setSpining, setLastMessageRef }) => {
  const [isLoading, setLoading] = useState(false);
  const [cursor, setCursor] = useState();
  const [page, setPage] = useState(initPage);
  const [messages, setMessages] = useState([]);
  const messageBottomRef = useRef(null);
  const boxChatRef = useRef(null);
  const { connection } = useGlobalContext();
  const screens = useBreakpoint();
  const { xs } = screens;
  const isMobile = xs;

  useEffect(() => {
    if (messageBottomRef !== undefined) setLastMessageRef(messageBottomRef);
  }, [messageBottomRef]);

  const fetchMessages = (defaultCursor) => {
    const currentCursor = defaultCursor === "" ? defaultCursor : cursor;
    const task = getMessagesCursor(
      conversationId,
      currentCursor,
      defaultPageSize
    );
    Promise.all([task, clearLoading]).then((response) => {
      const res = response[0];
      const {
        data: { page, data },
      } = res;
      setPage(page);
      let mess = data;
      if (defaultConversationId === conversationId)
        mess = [...mess, ...messages];

      mess = uniqBy(mess, "id");
      mess.sort(compareMessage);
      setMessages(mess);
      defaultConversationId = conversationId;
      setSpining(false);
    });
  };

  const configRender = () => {
    if (defaultConversationId !== conversationId) {
      isFirstRenderOfConversation = true;
    } else {
      isFirstRenderOfConversation = false;
    }
  };

  useEffect(() => {
    configRender();
    if (isFirstRenderOfConversation) return;
    fetchMessages();
  }, [cursor]);

  useEffect(() => {
    setPage(initPage);
    setCursor();
    fetchMessages("");
  }, [conversationId]);

  const scrollDown = () => {
    // const { offsetTop } = messageBottomRef.current;
    // console.log(offsetTop);
    // boxChatRef.current.scroll(0, offsetTop);

    messageBottomRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (connection === undefined) return;
    connection.on(RECEIVE_MESSAGE, (data) => {
      const { conversationId, id } = data;
      if (conversationId != defaultConversationId) return;

      let mess = [data, ...messages];
      if (!isEmpty(id)) mess = uniqBy(mess, "id");
      mess = mess.sort(compareMessage);
      setMessages(mess);
    });
  }, [connection, messages, messageBottomRef]);

  const handleNavigation = (e) => {
    const { scrollTop, offsetHeight, scrollHeight } = e.currentTarget;
    if (scrollTop + offsetHeight >= scrollHeight) {
      // handlerAtBottom();
    } else if (scrollTop <= 0) {
      handlerAtTop();
    }
  };

  const handlerAtTop = () => {
    if (cursor !== page.next && page.next !== "") {
      setCursor(page.next);
      setLoading(true);
    }
  };

  const clearLoading = new Promise((resolve, reject) => {
    setTimeout(() => {
      setLoading(false);
      resolve();
    }, 500);
  });

  useEffect(() => {
    if (isFirstRenderOfConversation) {
      isMobile || scrollDown();
      return;
    }

    isMobile && boxChatRef.current.scrollIntoView({ block: "start" });
  }, [messages]);

  const rowStyle = isMobile
    ? "p-t-b-15 box-chat-mobile box-chat-position"
    : "p-t-b-15 box-chat";

  return (
    <Row className={rowStyle} onScroll={handleNavigation}>
      <Col span={24} ref={boxChatRef}>
        {isLoading && (
          <Row className="justify-center">
            <Spin />
          </Row>
        )}
        <RenderMessages
          messages={messages}
          messageBottomRef={messageBottomRef}
        />
        <div ref={messageBottomRef}></div>
      </Col>
    </Row>
  );
};

function compareMessage(pre, next) {
  if (pre.sentDate > next.sentDate) return 1;
  if (pre.sentDate < next.sentDate) return -1;
  return 0;
}

function messagesPropsAreEqual(preState, nextState) {
  return preState.messages === nextState.messages;
}

const RenderMessages = memo(({ messages }) => {
  if (messages.length === 0) return <></>;
  return messages.map((item) => (
    <MessageAuthor
      key={item.id || uuidv4()}
      firstName={item.sender?.firstName}
      userId={item.sender?.id}
      id={item.id}
      lastName={item.sender?.lastName}
      content={item.content}
      sentDate={item.sentDate}
    />
  ));
}, messagesPropsAreEqual);

export default MessageBoard;
