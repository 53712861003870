import CollapseLottery from "./collapseLottery";
import { Row, Col, Divider } from "antd";
import UserInfor from "../../user-infor/user-infor";

const SideBarLottery = () => {
  return (
    <>
      <Row justify="center">
        <Col span={22} className="m-t-b-15">
          <UserInfor />
        </Col>
        <Divider></Divider>
        <Col span={24}>
          <CollapseLottery />
        </Col>
      </Row>
    </>
  );
};

export default SideBarLottery;
