import { Avatar, Typography, Grid } from "antd";

const { Title } = Typography;
const { useBreakpoint } = Grid;

const CountryCard = ({ name, imageSrc, isActive, keyName, onClickCountry }) => {
  const screens = useBreakpoint();
  const { xs, sm } = screens;

  const styleCard = isActive ? `country-box-active` : `country-box`;

  const onHandleClick = () => {
    if (!onClickCountry) return;
    if (isActive) return;
    onClickCountry(keyName);
  };

  return (
    <div className={styleCard} onClick={onHandleClick}>
      <div className="dp-inline-flex aglin-items-center">
        <Avatar size={70} alt={name} src={imageSrc} />
        <Title level={4} className="m-l-10">
          {name}
        </Title>
      </div>
    </div>
  );
};

export default CountryCard;
