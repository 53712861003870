import React, { useContext } from "react";

const initSetting: ISetting = {
  cursorUserTicketDashboard: undefined,
  setCursorUserTicketDashboard: undefined,
  cursorUserTicketManage: undefined,
  setCursorUserTicketManage: undefined,
  cursorLotteryDashboard: undefined,
  setCursorLotteryDashboard: undefined,
};
interface ISetting {
  cursorUserTicketDashboard: object | undefined;
  setCursorUserTicketDashboard: Function | undefined;
  cursorUserTicketManage: object | undefined;
  setCursorUserTicketManage: Function | undefined;
  cursorLotteryDashboard: object | undefined;
  setCursorLotteryDashboard: Function | undefined;
}
export const GlobalLotteryContext = React.createContext(initSetting);
export const useGlobalLotteryContext = () =>
  useContext<ISetting>(GlobalLotteryContext);

const userTicketSetting: IUserTicketSetting = {
  cursorUserTicketManage: undefined,
  setCursorUserTicketManage: undefined,
};
interface IUserTicketSetting {
  cursorUserTicketManage: object | undefined;
  setCursorUserTicketManage: Function | undefined;
}
export const UserTicketContext = React.createContext(userTicketSetting);
export const useUserTicketContext = () =>
  useContext<IUserTicketSetting>(UserTicketContext);
