import { Row, Col } from "antd";
import LotteryCard from "./lotteryCard";
import { isDigitalLottery } from "../../../helpers/lotteryHelper";
import useLongPress from "../../../hooks/useLongPress";

const LotteryGrid = ({ items, deleteEvent }) => {
  const { action, handlers, ref } = useLongPress();
  const isDisplay = action === "press";

  return (
    <Row className="bg-white mw-100 lottery-grid" ref={ref}>
      {items &&
        items.map((s) => (
          <Col
            {...handlers}
            key={`${s.id}-${s.quantity}`}
            xs={12}
            sm={12}
            md={isDigitalLottery(s.country) ? 6 : 4}
          >
            <LotteryCard
              {...s}
              isDisplayAction={isDisplay}
              deleteEvent={deleteEvent}
            />
          </Col>
        ))}
    </Row>
  );
};

export default LotteryGrid;
