import { GlobalLotteryContext } from "../../hooks/useGlobalLotteryContext.ts";
import { useState } from "react";

const LotteryProvider = ({ children }) => {
  const [cursorUserTicketManage, setCursorUserTicketManage] = useState({
    date: new Date(),
    countrySelected: undefined,
  });
  const [cursorUserTicketDashboard, setCursorUserTicketDashboard] = useState({
    date: new Date(),
    countrySelected: undefined,
  });
  const [cursorLotteryDashboard, setCursorLotteryDashboard] = useState({
    date: new Date(),
    countrySelected: undefined,
  });

  return (
    <GlobalLotteryContext.Provider
      value={{
        cursorUserTicketManage,
        setCursorUserTicketManage,
        cursorUserTicketDashboard,
        setCursorUserTicketDashboard,
        cursorLotteryDashboard,
        setCursorLotteryDashboard,
      }}
    >
      {children}
    </GlobalLotteryContext.Provider>
  );
};

export default LotteryProvider;
