import { Col, Typography } from "antd";
import { cloneDeep, debounce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const LotteryTable = ({ items, title, description }) => {
  const [lotteries, setLotteries] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (items.length === 0) return;
    setLotteries(items);
  }, [items]);

  if (items.length === 0) return <></>;
  const item = items[0];
  const effectiveDate = new Date(item.effectiveDate);

  const onHandlerFilterNumber = debounce((number) => {
    if (number === 0) {
      setLotteries(items);
      return;
    }

    const newItems = cloneDeep(items);
    for (const country of newItems) {
      const { lotteryItems } = country;
      for (const item of lotteryItems) {
        const length = item.textNumber.length;
        if (length > number) {
          item.textNumber = item.textNumber.slice(-number);
        }
      }
    }
    setLotteries(newItems);
  }, 400);

  return (
    <Col>
      <Col span={24}>
        <Title level={4}>{title}</Title>
        <Text>{description}</Text>
      </Col>
      <table>
        <tbody>
          <tr>
            <td className="p-0">
              <table height={496}>
                <tbody>
                  <tr>
                    <td className="lty-day">
                      <strong>
                        {t("lottery_page.day", {
                          val: effectiveDate,
                          formatParams: {
                            val: {
                              weekday: "long",
                            },
                          },
                        })}
                      </strong>
                    </td>
                  </tr>
                  <tr className="lty-border">
                    <td height={49}>{t("lottery_page.eighth")}</td>
                  </tr>
                  <tr className="lty-border lty-bg">
                    <td height={25}>{t("lottery_page.seventh")}</td>
                  </tr>
                  <tr className="lty-border">
                    <td height={69}>{t("lottery_page.sixth")}</td>
                  </tr>
                  <tr className="lty-border lty-bg">
                    <td height={25}>{t("lottery_page.fiveth")}</td>
                  </tr>
                  <tr className="lty-border">
                    <td height={157}>{t("lottery_page.fourth")}</td>
                  </tr>
                  <tr className="lty-border lty-bg">
                    <td height={47}>{t("lottery_page.threeth")}</td>
                  </tr>
                  <tr className="lty-border">
                    <td height={25}>{t("lottery_page.second")}</td>
                  </tr>
                  <tr className="lty-border lty-bg">
                    <td height={25}>{t("lottery_page.first")}</td>
                  </tr>
                  <tr className="lty-border">
                    <td height={49}>{t("lottery_page.special")}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className="p-0">
              <table>
                <tbody>
                  <tr>
                    {lotteries &&
                      lotteries.map((s) => (
                        <CountryColumn
                          key={`${s.countryCode}-${s.effectiveDate}`}
                          {...s}
                        />
                      ))}
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="lty-bot-table">
        <tbody>
          <tr className="lty-bot-tr">
            <td className="lty-bot-btn">
              <div onClick={() => onHandlerFilterNumber(0)}>
                {t("lottery_page.btnNormal")}
              </div>
              <div onClick={() => onHandlerFilterNumber(2)}>
                {t("lottery_page.btnTwo")}
              </div>
              <div onClick={() => onHandlerFilterNumber(3)}>
                {t("lottery_page.btnThree")}
              </div>
              <div onClick={() => onHandlerFilterNumber(4)}>
                {t("lottery_page.btnFour")}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Col>
  );
};

const CountryColumn = ({
  country,
  countryCode,
  countryNormalize,
  effectiveDate,
  id,
  lotteryItems,
}) => {
  const levelGroup = groupBy(lotteryItems, "level");
  const levelArr = Object.entries(levelGroup).reverse();
  const mappingActiveItem = lotteryItems.reduce(
    (values, cur) => ({
      ...values,
      [cur.id]: {
        id: cur.id,
        active: false,
      },
    }),
    {}
  );

  const [activeItem, setActiveItem] = useState(mappingActiveItem);

  const onHandlerClick = (idRequest) => {
    const newItems = { ...activeItem };
    for (const key in newItems) {
      const item = activeItem[key];
      item.active = key === idRequest;
    }
    setActiveItem(newItems);
  };

  const getClassName = (idRequest) => {
    const hasKey = activeItem[idRequest];
    if (!hasKey) return "";
    return activeItem[idRequest].active ? "lty-active" : "";
  };

  return (
    <td className="p-0">
      <table>
        <tbody>
          <tr>
            <td>
              <strong>{country}</strong>
            </td>
          </tr>
          {levelArr &&
            levelArr.map((s) => (
              <tr key={s[0]} className={getClassNameLottery(s[0])}>
                <td>
                  {s[1].map((x) => (
                    <div
                      className={getClassName(x.id)}
                      onClick={() => onHandlerClick(x.id)}
                      key={x.id}
                    >
                      {x.textNumber}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </td>
  );
};

const getClassNameLottery = (level) => {
  const numberLevel = parseInt(level);
  switch (numberLevel) {
    case 8:
      return "lty-eighth";
    case 7:
      return "lty-seventh lty-bg";
    case 6:
      return "lty-sixth";
    case 5:
      return "lty-fiveth lty-bg";
    case 4:
      return "lty-fourth";
    case 3:
      return "lty-threeth lty-bg";
    case 2:
      return "lty-second";
    case 1:
      return "lty-first lty-bg";
    case 0:
      return "lty-special";
    default:
      return "";
  }
};

const groupBy = (input, key) => {
  return input.reduce((acc, currentValue) => {
    let groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
};

export default LotteryTable;
