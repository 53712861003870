import { Avatar, Row, Col, Tooltip } from "antd";
import { zonedDate } from "../../helpers/dateHepler";
import { formatDistance } from "date-fns";
import ico from "../../assets/icon.jpg";
import { useNavigate } from "react-router-dom";

const MessageAuthor = (props) => {
  const { firstName, lastName, content, sentDate, id, userId } = props;
  const fullname = `${firstName} ${lastName}`;
  const firstCharacter = firstName.split("")[0];
  const navigate = useNavigate();

  const date = formatDistance(zonedDate(sentDate), new Date(), {
    addSuffix: true,
  });

  const onNavigateUserInfo = () => {
    const userInfoLink = `/users/${userId}`;
    navigate(userInfoLink);
  };

  return (
    <Row className="m-b-10">
      <Col className="m-r-15">
        {id ? <Avatar>{firstCharacter}</Avatar> : <Avatar src={ico}></Avatar>}
      </Col>
      <Col xs={15} sm={20} md={20} lg={22} xl={22}>
        <p className="m-0 f-w-bold" onClick={onNavigateUserInfo}>
          {id ? fullname : "System"}
        </p>
        <Tooltip placement="right" title={date}>
          <p className="m-0 p_wrap">{content}</p>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default MessageAuthor;
