import { Input, Typography } from "antd";
import { useField } from "formik";

const { Text } = Typography;

const NumberField = ({ name, label, type, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const onHandleChange = (e) => {
    const {
      target: { value },
    } = e;

    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      helpers.setValue(value);
    }
  };

  return (
    <div>
      <Input
        {...props}
        name={name}
        label={label}
        type={type}
        {...field}
        value={field.value}
        defaultValue={field.value}
        onChange={onHandleChange}
      />
      {meta.touched && meta.error ? (
        <Text type="danger">{meta.error}</Text>
      ) : null}
    </div>
  );
};

export default NumberField;
