import { useEffect, useState } from "react";
import BaseFormik from "../../../../componentsUltils/Formik/BaseFormik";
import { Row, Col, Typography, Divider, Table, Button } from "antd";
import { getUserTicketsOfMe, checkUserTicketById } from "../../../../services/userTicketService";
import { countryDic } from "../../../../helpers/countryHelper";
import { format } from "date-fns";

const { Title, Text } = Typography;

const UserTicketGrid = () => {
  const [usertickets, setUserTickets] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: text => <Title level={5}>{format(new Date(text), 'yyyy-MM-dd')}</Title>,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      sorter: true,
      render: text => <Title level={5}>{countryDic[text].name}</Title>,
    },
    {
      title: 'Ticket',
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
      render: text => <Title level={4} type="danger">{ticketFormat(text)}</Title>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: text => <Title level={5}>{text}</Title>,
    },
    {
      title: 'Result',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: text => displayStatus(text),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_, { status, id }) => status === 'New' && <Button type="primary" onClick={() => onHandlerCheckUserTicket(id)}>Check</Button>,
    },
  ];

  const onHandlerCheckUserTicket = async (id) => {
    setLoading(true)
    const result = await checkUserTicketById(id);
    setLoading(false)
    await fetchData();
  }

  const onHandlerSort = ({ field, order }) => {
    if (field === undefined) return undefined;
    return { key: field === 'status' ? 'ticketResult' : field === 'country' ? 'countryNormalize' : field, value: order === 'descend' ? 'desc' : 'asc' }
  }

  async function fetchData() {
    setLoading(true);
    const sort = onHandlerSort(tableParams);
    const response = await getUserTicketsOfMe(tableParams.pagination.current, tableParams.pagination.pageSize, sort)
    const { data: { data, paged } } = response;
    const items = data.map(s => ({ ...s, key: s.id }))
    setUserTickets(items);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: paged.totalCount,
      },
    });
    setLoading(false);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)])

  const handleTableChange = (
    pagination,
    filters,
    sorter,
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return <BaseFormik formik={{}}>
    <Row>
      <div className="userticketgrid-title">
        <Title level={4}>Grid</Title>
        <Divider />
      </div>
    </Row>
    <Row className="bg-white">
      <Col className="p-l-20 m-t-20" span={24}>
        <Title level={4}>UserTickets</Title>
      </Col>
      <Col className="p-l-20 p-r-20 m-t-20" span={24}>
        <Table dataSource={usertickets} columns={columns} pagination={tableParams.pagination} loading={loading} onChange={handleTableChange} />;
      </Col>
    </Row>
  </BaseFormik>
}

const displayStatus = (text) => {
  if (text === 'Won') return <Text type="success">{text}</Text>

  if (text === 'Lose') return <Text type="secondary">{text}</Text>

  return <Text>{text}</Text>
}

const ticketFormat = (txt) => {
  const isDigital = txt.length > 6;
  const arrNumber = txt.match(/.{1,2}/g);
  const ticketNumberLabel = isDigital ? arrNumber.join(" ") : txt;
  return ticketNumberLabel;
}

export default UserTicketGrid;