import { useState } from "react";
import { Modal, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../services/userService";
import useEffectOne from "../../hooks/useHook";
import { useGlobalContext } from "../../hooks/useGlobalContext.ts";
import BaseFormik from "../../componentsUltils/Formik/BaseFormik.jsx";
import * as Yup from "yup";
import TextField from "../../componentsUltils/Field/TextField.jsx";
import { useFormik } from "formik";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Required").email(),
  firstName: Yup.string().min(2).required("Required").typeError("Invalid"),
  lastName: Yup.string().min(2).required("Required").typeError("Invalid"),
  username: Yup.string().min(5).required("Required").typeError("Invalid"),
  password: Yup.string().required("Required").min(8).typeError("Invalid"),
});

const SignUp = () => {
  const [open, setOpen] = useState(true);
  const { connection } = useGlobalContext();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();

  useEffectOne(() => {
    if (connection === undefined) return;

    const { state } = connection;

    if (state === "Connected") navigate("/");
  });

  const handleOk = (user) => {
    setConfirmLoading(true);
    signUp(user)
      .then(() => {
        setOpen(false);
        setConfirmLoading(false);
        navigate("/login");
      })
      .catch((error) => {
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setOpen(false);
    navigate("/login");
  };

  const formik = useFormik({
    initialValues: {
      username: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      password: undefined,
    },
    validationSchema,
    onSubmit: (values) => {
      handleOk(values);
    },
  });

  return (
    <BaseFormik formik={formik}>
      <Modal
        title="SignUp"
        open={open}
        onOk={formik.handleSubmit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Login"
        maskClosable={false}
        closable={false}
        spacing={3}
      >
        <Space
          direction="vertical"
          size={"middle"}
          style={{ marginRight: "10px" }}
        >
          <TextField
            placeholder="First Name"
            id="firstName"
            name="firstName"
            prefix={<UserOutlined />}
          />

          <TextField
            placeholder="Last Name"
            id="lastName"
            name="lastName"
            prefix={<UserOutlined />}
          />

          <TextField
            placeholder="Email"
            id="email"
            name="email"
            prefix={<UserOutlined />}
          />
        </Space>
        <Space direction="vertical" size={"middle"}>
          <TextField
            placeholder="Username"
            id="username"
            name="username"
            prefix={<UserOutlined />}
          />

          <TextField
            placeholder="Password"
            id="password"
            name="password"
            type="password"
            prefix={<UserOutlined />}
          />
        </Space>
      </Modal>
    </BaseFormik>
  );
};

export default SignUp;
