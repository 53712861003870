import { Row, Skeleton } from "antd";

const MainSkelethon = () => {
  return (
    <Row>
      <Skeleton active />;
    </Row>
  );
};

export default MainSkelethon;
