import { Menu, Grid } from "antd";
import DatePickerField from "../Field/DatePickerField";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { isDate } from "lodash";

const { useBreakpoint } = Grid;
const twoMonthAgo = new Date();
twoMonthAgo.setMonth(twoMonthAgo.getMonth() - 2);

const MenuNavigate = ({ title, id, name }) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const screens = useBreakpoint();
  const { xs } = screens;
  const isMobile = xs;
  const keyMenuSelected = getKeyMenu(field.value);

  const items = [
    {
      key: "title",
      label: (
        <h1>
          <strong>{title}</strong>
        </h1>
      ),
      disabled: true,
    },
    {
      disabled: true,
      key: "",
      label: "",
    },
    {
      key: "previousDay",
      label: t("yesterday"),
    },
    {
      key: "today",
      label: t("today"),
    },
    {
      key: "menualDate",
      label: (
        <DatePickerField
          minDate={twoMonthAgo}
          id={id}
          name={name}
          placeholder={t("manualDate")}
        />
      ),
    },
  ];

  const onHandleClick = (e) => {
    const { key } = e;

    const dateMenu = dateDic[key];
    if (dateMenu === undefined) return;
    helpers.setValue(dateMenu);
  };

  return (
    <Menu
      selectedKeys={[keyMenuSelected]}
      mode={isMobile ? "vertical" : "horizontal"}
      style={{ minWidth: 0, flex: "auto" }}
      onClick={onHandleClick}
      items={items}
    />
  );
};

const now = new Date();
const yesterday = new Date(now - 86400000);

const dateMap = new Map([
  ["previousDay", yesterday],
  ["today", now],
  ["manualDate", undefined],
]);

const getKeyMenu = (dateRequest) => {
  if (!isDate(dateRequest)) return "manualDate";

  const date = new Date(dateRequest.toISOString().split("T")[0]);

  const dateYesterday = new Date(yesterday.toISOString().split("T")[0]);
  if (date.getTime() === dateYesterday.getTime()) return "previousDay";

  const dateNow = new Date(now.toISOString().split("T")[0]);
  if (date.getTime() === dateNow.getTime()) return "today";

  return "manualDate";
};

export const dateDic = Object.fromEntries(dateMap);

export default MenuNavigate;
