import TienGiangImage from "../assets/images/tiengiang.webp";
import KienGiangImage from "../assets/images/kiengiang.webp";
import DalatImage from "../assets/images/dalat.webp";
import TpHCMImage from "../assets/images/tp.hcm.webp";
import VungTauImage from "../assets/images/vungtau.webp";
import BacLieuImage from "../assets/images/baclieu.webp";
import DongNaiImage from "../assets/images/dongnai.webp";
import CanThoImage from "../assets/images/cantho.webp";
import SocTrangImage from "../assets/images/soctrang.webp";
import TayNinhImage from "../assets/images/tayninh.webp";
import AnGiangImage from "../assets/images/angiang.webp";
import BinhThuanImage from "../assets/images/binhthuan.webp";
import VinhLongImage from "../assets/images/vinhlong.webp";
import BinhDuongImage from "../assets/images/binhduong.webp";
import TraVinhImage from "../assets/images/travinh.webp";
import LongAnImage from "../assets/images/longan.webp";
import BinhPhuocImage from "../assets/images/binhphuoc.webp";
import HauGiangImage from "../assets/images/haugiang.webp";
import CaMauImage from "../assets/images/camau.webp";
import BenTreImage from "../assets/images/bentre.webp";
import MegaImage from "../assets/images/mega.webp";
import PowerImage from "../assets/images/power.webp";

const countryMap = new Map([
  ["tiengiang", { name: "Tiền Giang", imageSrc: TienGiangImage }],
  ["kiengiang", { name: "Kiên Giang", imageSrc: KienGiangImage }],
  ["dalat", { name: "Đà Lạt", imageSrc: DalatImage }],
  ["tp.hcm", { name: "TP. HCM", imageSrc: TpHCMImage }],
  ["vungtau", { name: "Vũng Tàu", imageSrc: VungTauImage }],
  ["baclieu", { name: "Bạc Liêu", imageSrc: BacLieuImage }],
  ["dongnai", { name: "Đồng Nai", imageSrc: DongNaiImage }],
  ["cantho", { name: "Cần Thơ", imageSrc: CanThoImage }],
  ["soctrang", { name: "Sóc Trăng", imageSrc: SocTrangImage }],
  ["tayninh", { name: "Tây Ninh", imageSrc: TayNinhImage }],
  ["angiang", { name: "An Giang", imageSrc: AnGiangImage }],
  ["binhthuan", { name: "Bình Thuận", imageSrc: BinhThuanImage }],
  ["vinhlong", { name: "Vĩnh Long", imageSrc: VinhLongImage }],
  ["binhduong", { name: "Bình Dương", imageSrc: BinhDuongImage }],
  ["travinh", { name: "Trà Vinh", imageSrc: TraVinhImage }],
  ["longan", { name: "Long An", imageSrc: LongAnImage }],
  ["binhphuoc", { name: "Bình Phước", imageSrc: BinhPhuocImage }],
  ["haugiang", { name: "Hậu Giang", imageSrc: HauGiangImage }],
  ["camau", { name: "Cà Mau", imageSrc: CaMauImage }],
  ["dongthap", { name: "Đồng Tháp", imageSrc: CaMauImage }],
  ["bentre", { name: "Bến Tre", imageSrc: BenTreImage }],
  ["mega", { name: "Mega", imageSrc: MegaImage }],
  ["power", { name: "Power", imageSrc: PowerImage }],
]);

const countryInWeek = new Map([
  ["Sunday", ["tiengiang", "kiengiang", "dalat", "mega"]],
  ["Monday", ["tp.hcm", "dongthap", "camau"]],
  ["Tuesday", ["bentre", "vungtau", "baclieu", "power"]],
  ["Wednesday", ["dongnai", "cantho", "soctrang", "mega"]],
  ["Thursday", ["tayninh", "angiang", "binhthuan", "power"]],
  ["Friday", ["vinhlong", "binhduong", "travinh", "mega"]],
  ["Saturday", ["tp.hcm", "longan", "binhphuoc", "haugiang", "power"]],
]);

const southCountryInWeek = new Map([
  ["Sunday", ["tiengiang", "kiengiang", "dalat"]],
  ["Monday", ["tp.hcm", "dongthap", "camau"]],
  ["Tuesday", ["bentre", "vungtau", "baclieu"]],
  ["Wednesday", ["dongnai", "cantho", "soctrang"]],
  ["Thursday", ["tayninh", "angiang", "binhthuan"]],
  ["Friday", ["vinhlong", "binhduong", "travinh"]],
  ["Saturday", ["tp.hcm", "longan", "binhphuoc", "haugiang"]],
]);

export const southCountryWeekDic = Object.fromEntries(southCountryInWeek);
export const countryWeekDic = Object.fromEntries(countryInWeek);
export const countryDic = Object.fromEntries(countryMap);
