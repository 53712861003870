import { Col, Row } from "antd";
import CountryCard from "./countryCard";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const CountryGrid = ({ items, onCountrySelect }) => {
  const { t } = useTranslation();
  let spanNumber = 6;

  if (!items) return <></>;

  if (items.length === 5) spanNumber = 4;

  return (
    <Row className="bg-white">
      <Col className="p-l-20 m-t-20" span={24}>
        <Title level={4}>{t("userticket_page.country")}</Title>
      </Col>
      {items &&
        items.map((s) => (
          <Col
            key={`${s.keyName}-${s.isActive}`}
            span={spanNumber}
            xs={12}
            sm={12}
            md={spanNumber}
          >
            <CountryCard {...s} onClickCountry={onCountrySelect} />
          </Col>
        ))}
    </Row>
  );
};

export default CountryGrid;
