import { useTranslation } from "react-i18next";
import UserTicketCard from "./userTicketCard";
import { Col, Row, Typography } from "antd";

const { Title } = Typography;

const GridSection = ({ items }) => {
  const { t } = useTranslation();
  const alreadyTicketsInday = items !== undefined && items.length > 0;
  if (!alreadyTicketsInday) return <></>;

  return (
    <>
      <Row className="bg-white">
        <Col className="p-l-20 m-t-20" span={24}>
          <Title level={4}>
            {t("dashboard_page.sections.userticketInDay")}
          </Title>
        </Col>
      </Row>
      <Row className="bg-white" gutter={10}>
        {items &&
          items.map((s) => (
            <Col key={s.id} span={12} xs={12} sm={12} md={8} lg={5}>
              <UserTicketCard key={s.id} item={s} />
            </Col>
          ))}
      </Row>
    </>
  );
};

export default GridSection;
