import { useState } from 'react'

const timeDelay = 3000;
const useAlert = () => {
  const [alert, setAlert] = useState();

  const callAlert = () => {
    function success(message) {
      setAlert({
        type: 'success',
        message
      })
      clearAlert();
    }

    function info(message) {
      setAlert({
        type: 'info',
        message
      })
      clearAlert();
    }

    function error(message) {
      setAlert({
        type: 'error',
        message
      })
      clearAlert();
    }

    function warning(message) {
      setAlert({
        type: 'warning',
        message
      })
      clearAlert();
    }

    return {
      success, info, error, warning
    }
  };

  const clearAlert = () => {
    setTimeout(() => { setAlert() }, timeDelay)
  }

  return {
    alert,
    callAlert
  }
}

export default useAlert;