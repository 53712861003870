import { useTranslation } from "react-i18next";
import { Row, Col, Typography, Space } from "antd";
import { Pie, Line } from "@ant-design/plots";
import { countryDic } from "../../../../helpers/countryHelper";
import useEffectOne from "../../../../hooks/useHook";
import { useState } from "react";
import { getUserTicketsByRangeDate } from "../../../../services/userTicketService";

const { Title, Text } = Typography;

const now = new Date();
const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

const AnalysisSection = ({ items }) => {
  const [userTicketsInMonth, setUserTicketsInMonth] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalWonCount, setTotalWonCount] = useState(0);
  const [wonAmount, setWonAmount] = useState(0);
  const { t } = useTranslation();
  const alreadyTicketsInday = items !== undefined && items.length > 0;

  useEffectOne(() => {
    getUserTicketsByRangeDate(firstDay, lastDay).then((res) => {
      const {
        data: { data },
      } = res;
      const totalCount = data.reduce((value, pre) => value + pre.quantity, 0);
      const totalWonTickets = data.filter((s) => s.ticketResult?.isWon);
      const totalWonCount = totalWonTickets.reduce(
        (value, pre) => value + pre.quantity,
        0
      );
      const wonAmount = totalWonTickets.reduce(
        (amount, pre) => amount + pre.ticketResult?.amount,
        0
      );
      setTotalCount(totalCount);
      setTotalWonCount(totalWonCount);
      setWonAmount(wonAmount);
      setUserTicketsInMonth(data);
    });
  });

  return (
    <>
      <Row className="bg-white">
        <Col className="p-l-20 m-t-20" span={24}>
          <Title level={4}>{t("dashboard_page.analysis")}</Title>
        </Col>
      </Row>
      {alreadyTicketsInday && (
        <Row className="bg-white">
          <Col className="p-l-20 m-t-20" span={24}>
            <Title level={4}>
              {t("dashboard_page.sections.analysis.inDay")}
            </Title>
          </Col>
          <Col xs={24} sm={12} md={10} className="p-l-20 m-t-20">
            <TotalPieChart items={items} />
          </Col>
        </Row>
      )}
      <Row className="bg-white">
        <Col className="p-l-20 m-t-20" span={24}>
          <Title level={4}>
            {t("dashboard_page.sections.analysis.inMonth", {
              val: now,
              formatParams: {
                val: {
                  month: "long",
                },
              },
            })}
          </Title>
        </Col>
        <Col xs={24} sm={12} md={10} className="p-l-20 m-t-20">
          <Space direction="vertical">
            <Text strong>
              {t("dashboard_page.sections.analysis.totalCount", {
                val: totalCount,
              })}
            </Text>
            <Text strong>
              {t("dashboard_page.sections.analysis.totalWonCount", {
                val: totalWonCount,
              })}
            </Text>
            <Text strong>
              {t("dashboard_page.sections.analysis.wonAmount", {
                val: wonAmount,
              })}
            </Text>
          </Space>
          <RatePieChart items={userTicketsInMonth} />
        </Col>
        <Col xs={24} sm={12} md={10} className="p-l-20 m-t-20">
          {/* TODO in month */}
          <InMonthLineChart items={userTicketsInMonth} />
        </Col>
      </Row>
    </>
  );
};

const RatePieChart = ({ items }) => {
  const { t } = useTranslation();
  if (items === undefined || items.length === 0) return <>Empty data</>;

  const totalWonTickets = items
    .filter((s) => s.ticketResult?.isWon)
    .reduce((value, p) => value + p.quantity, 0);
  const totalNoCheckTickets = items
    .filter((s) => s.ticketResult === null)
    .reduce((value, p) => value + p.quantity, 0);

  const totalLoseTickets = items
    .filter((s) => s.ticketResult !== null)
    .filter((s) => !s.ticketResult.isWon)
    .reduce((value, p) => value + p.quantity, 0);

  const config = {
    data: [
      { type: t("dashboard_page.sections.analysis.wonIndicator"), value: totalWonTickets },
      { type: t("dashboard_page.sections.analysis.loseIndicator"), value: totalLoseTickets },
      { type: t("dashboard_page.sections.analysis.noCheckIndicator"), value: totalNoCheckTickets },
    ],
    angleField: "value",
    colorField: "type",
    label: {
      text: (s) => `${s.value} ${t("count")}`,
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 5,
      },
    },
  };

  return <Pie {...config} />;
};

const TotalPieChart = ({ items }) => {
  if (items === undefined || items.legend === 0)
    return <Text>Empty data for chart</Text>;

  const models = items.reduce(
    (totals, p) => ({
      ...totals,
      [p.country]: (totals[p.country] || 0) + p.quantity,
    }),
    {}
  );
  const data = [];
  for (const k in models) {
    const itemCount = models[k];
    const value = { type: countryDic[k].name, value: itemCount };
    data.push(value);
  }
  const config = {
    data,
    angleField: "value",
    colorField: "type",
    label: {
      text: (s) => `${s.value} Vé`,
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 5,
      },
    },
  };
  return <Pie {...config} />;
};

const InMonthLineChart = ({ items }) => {
  const models = items.reduce(
    (totals, pre) => ({
      ...totals,
      [new Date(pre.date).getDate()]:
        (totals[new Date(pre.date).getDate()] || 0) + pre.quantity,
    }),
    {}
  );
  const data = [];
  let i = firstDay.getDate();
  const dayLength = lastDay.getDate();
  for (i; i <= dayLength; i++) {
    let itemDefault = { Day: i, Count: 0 };
    const itemCount = models[i];
    if (itemCount === undefined) {
      data.push(itemDefault);
      continue;
    }
    itemDefault.Count = itemCount;
    data.push(itemDefault);
  }

  const config = {
    data,
    xField: "Day",
    yField: "Count",
    point: {
      shapeField: "square",
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
    text: "Vé",
  };
  return <Line {...config} />;
};

export default AnalysisSection;
