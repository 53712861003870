import PageContainer from "../page-container/page-container";
import MessageBoard from "../message-board/message-board";
import TextEditor from "../text-editor/text-editor";
import { useParams } from "react-router-dom";
import { getConversationById } from "../../services/newsService";
import { useEffect, useState } from "react";
import { Spin } from "antd";

function Conversation() {
  const [lastMessageRef, setLastMessageRef] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [conversation, setConversation] = useState({
    id: undefined,
    memberCount: undefined,
  });

  useEffect(() => {
    setLoading(true);
    getConversationById(id).then((res) => {
      const { data } = res;
      setConversation(data);
    });
  }, [id]);

  const scrollDownBoard = () => {
    lastMessageRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <PageContainer
      conversationId={id}
      title={conversation.name}
      description={conversation.description}
      memberCount={conversation.memberCount}
    >
      <Spin tip="Loading..." size="large" spinning={loading}>
        <MessageBoard
          conversationId={id}
          setSpining={setLoading}
          setLastMessageRef={setLastMessageRef}
        />
        <TextEditor conversationId={id} scrollDownBoard={scrollDownBoard} />
      </Spin>
    </PageContainer>
  );
}

export default Conversation;
