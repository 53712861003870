import { Select, Typography } from "antd";
import React, { useEffect } from "react";
import { useField } from "formik";
import { find } from "lodash";

const { Text } = Typography;

const SelectField = ({ name, label, items, ...props }) => {
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (items.length === 0) return;
    if (meta.value === undefined) return;

    const newValue = find(items, ["value", meta?.value]);
    if (newValue === undefined) {
      helpers.setValue(newValue);
      return;
    }

    helpers.setValue(newValue.value);
  }, [items, meta.value]);

  const onChange = (value) => {
    helpers.setValue(value);
  };

  const onSearch = (value) => {
    helpers.setValue(value);
  };

  return (
    <div>
      <Select
        {...props}
        showSearch
        value={meta.value}
        defaultValue={meta.value}
        onSearch={onSearch}
        optionFilterProp="children"
        onChange={onChange}
        onBlur={field.onBlur}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={items}
        style={{ width: "100%" }}
      />
      {meta.touched && meta.error ? (
        <Text type="danger">{meta.error}</Text>
      ) : null}
    </div>
  );
};

//  options={[
//       {
//         value: "jack",
//         label: "Jack",
//       },
//       {
//         value: "lucy",
//         label: "Lucy",
//       },
//       {
//         value: "tom",
//         label: "Tom",
//       },
//     ]}

export default SelectField;
