import { useEffect, useState } from "react";
import Dialog from "../../../../componentsUltils/modals/dialog";
import * as Yup from "yup";
import BaseFormik from "../../../../componentsUltils/Formik/BaseFormik";
import NumberField from "../../../../componentsUltils/Field/NumberField";
import SelectField from "../../../../componentsUltils/Field/SelectField";
import DatePickerField from "../../../../componentsUltils/Field/DatePickerField";
import { format } from "date-fns";
import { countryDic } from "../../../../helpers/countryHelper.js";
import { useFormik } from "formik";
import { countryWeekDic } from "../../../../helpers/countryHelper.js";
import { zonedTimeToUtc } from "date-fns-tz";
import { useTranslation } from "react-i18next";

const digitalLottery = ["mega", "power"];

const validationSchema = Yup.object().shape({
  ticketNumber: Yup.string()
    .required("Required")
    .typeError("Invalid")
    .length(6, "TicketNumber should length equals 6")
    .when("countryNormalize", (values, schema) => {
      const countrySelected = values[0];
      return digitalLottery.includes(countrySelected)
        ? schema.length(12, "TicketNumber should length equals 12")
        : schema;
    }),
  date: Yup.date()
    .required("Required")
    .test(
      "invalidPast",
      `Cannot select dates in the past`,
      (value, context) => {
        const isValid = validDateCreateTicket(value);
        return isValid;
      }
    ),
  countryNormalize: Yup.string().required("Required"),
  quantity: Yup.number().required("Required").max(100).typeError("Invalid"),
});

const UserTicketDialog = (props) => {
  const {
    data: { quantity, date: defaultDate, country },
  } = props;
  const { t } = useTranslation();
  const [date, setDate] = useState(defaultDate);
  const [countries, setCountries] = useState([]);
  const formik = useFormik({
    initialValues: {
      ticketNumber: "",
      date: date,
      countryNormalize: country,
      quantity: quantity,
    },
    validationSchema,
    validateOnMount: true,
  });

  const onHandleChange = (dateMoment, dateString) => {
    if (dateMoment === null) {
      return setDate(dateMoment);
    }
    const date = new Date(dateString);
    setDate(date);
  };

  useEffect(() => {
    if (date === null || date === undefined) {
      return setCountries([]);
    }
    const day = format(date, "EEEE");
    const ctries = countryWeekDic[day];

    const ctriesItem = ctries.map((c) => ({
      value: c,
      label: countryDic[c].name,
    }));
    setCountries(ctriesItem);
  }, [date]);

  return (
    <BaseFormik formik={formik}>
      <Dialog title={t("userticket_page.manage.btn_create")} {...props}>
        <DatePickerField
          allowClear={false}
          placeholder="Date"
          id="date"
          name="date"
          onChange={onHandleChange}
        />
        <br />
        <SelectField
          placeholder="Country"
          id="countryNormalize"
          name="countryNormalize"
          items={countries}
        />
        <br />
        <NumberField
          placeholder="Ticket Number"
          label="Ticket Number"
          id="ticketNumber"
          name="ticketNumber"
        />
        <br />
        <NumberField
          placeholder="Quantity"
          id="quantity"
          name="quantity"
          label="Quantity"
        />
      </Dialog>
    </BaseFormik>
  );
};

const validDateCreateTicket = (requestFullDate) => {
  const currentFullDate = zonedTimeToUtc(Date.now(), "Asia/Ho_Chi_Minh");
  const currentDate = new Date(
    currentFullDate.getFullYear(),
    currentFullDate.getMonth(),
    currentFullDate.getDate()
  );
  const requestDate = new Date(
    requestFullDate.getFullYear(),
    requestFullDate.getMonth(),
    requestFullDate.getDate()
  );

  const isFuture = requestDate.getTime() > currentDate.getTime();
  if (isFuture) return isFuture;

  const isToday =
    currentFullDate.getFullYear() === requestFullDate.getFullYear() &&
    currentFullDate.getMonth() === requestFullDate.getMonth() &&
    currentFullDate.getDate() === requestFullDate.getDate();
  if (isToday) return currentFullDate.getHours() <= 16; // Hours

  return false;
};

export default UserTicketDialog;
