import Axios from "./axios";

const USER_KEY = "user";

export function setUserToLocalStorage(user) {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
}

export function clearUserFromLocalStorage() {
  localStorage.removeItem(USER_KEY);
}

export function getUserFromLocalStorage() {
  const user = localStorage.getItem(USER_KEY);
  return JSON.parse(user);
}

export const getCurrentUser = () =>
  Axios({
    withCredentials: true,
    url: "/news/users/me",
    method: "GET",
  });

export const signIn = (username, password) =>
  Axios({
    withCredentials: true,
    url: `/news/users/signin`,
    method: "POST",
    data: { username, password },
  });

export const signOut = () =>
  Axios({
    withCredentials: true,
    url: `/news/users/signout`,
    method: "GET",
  });

export const signUp = ({ username, firstName, lastName, email, password }) =>
  Axios({
    withCredentials: true,
    url: `/news/users`,
    method: "POST",
    data: {
      name: username,
      firstName,
      lastName,
      email,
      password,
    },
  });
