import { Row, Col, Avatar, Typography } from "antd";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getUserById } from "../../services/newsService";
import { formatDistance } from "date-fns";
import { zonedDate } from "../../helpers/dateHepler";

const { Title } = Typography;

const UserDetail = () => {
  const { id } = useParams();
  const [user, setUser] = useState({ firstName: "", lastName: "" });

  useEffect(() => {
    getUserById(id).then((res) => {
      const { data } = res;
      setUser(data);
    });
  }, [id]);

  const fullname = `${user?.firstName} ${user?.lastName}`;
  const firstCharacter = fullname.split("")[0];
  const joined =
    user?.createdUtc === undefined
      ? undefined
      : formatDistance(zonedDate(user?.createdUtc), new Date(), {
          addSuffix: true,
        });

  return (
    <Row justify="center" className="m-h-auto">
      <Col className="box-profile" xs={20} sm={15} md={15} lg={10} xl={7}>
        <Col>
          <Title className="f-dancing" level={3}>
            <Avatar>{firstCharacter}</Avatar>
            &ensp;
            {fullname}
          </Title>
          <Typography>Joined at: {joined}</Typography>
        </Col>
      </Col>
    </Row>
  );
};

export default UserDetail;
