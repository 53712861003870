import { Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useGlobalLotteryContext } from "../../../hooks/useGlobalLotteryContext.ts";
import { getLotteryByDate } from "../../../services/lotteryService.js";
import { format } from "date-fns";
import { southCountryWeekDic } from "../../../helpers/countryHelper";
import LotteryTable from "./lotteryTable.jsx";
import { useTranslation } from "react-i18next";
import LotteryDigitalTable from "./lotteryDigitalTable.jsx";

const { Title } = Typography;
const digitalCountries = ["mega", "power"];

const LotterySection = () => {
  const { t } = useTranslation();
  const [southLotteies, setSouthLotteries] = useState([]);
  const [middleLotteies, setMiddleLotteries] = useState([]);
  const [digitalLottery, setDigitalLottery] = useState(undefined);
  const { cursorLotteryDashboard } = useGlobalLotteryContext();

  useEffect(() => {
    const { date } = cursorLotteryDashboard;
    const day = format(date, "EEEE");
    const ctries = southCountryWeekDic[day];

    getLotteryByDate({ date }).then((res) => {
      const {
        data: { data },
      } = res;
      const models = data
        .filter(
          (s) => !digitalCountries.includes(s.countryNormalize.toLowerCase())
        )
        .map((s) => ({
          ...s,
          isSouth: ctries.includes(s.countryNormalize),
        }));
      const innerDigitalLottery = data.filter((s) =>
        digitalCountries.includes(s.countryNormalize.toLowerCase())
      );

      setDigitalLottery(innerDigitalLottery[0]);
      setMiddleLotteries(models.filter((s) => !s.isSouth));
      setSouthLotteries(models.filter((s) => s.isSouth));
    });
  }, [cursorLotteryDashboard.date]);

  return (
    <>
      <Row className="bg-white">
        <Col className="p-l-20 m-t-20" span={24}>
          <Title level={4}>{t("lottery_page.lotterySection")}</Title>
        </Col>
      </Row>
      <Row className="bg-white p-l-20">
        <Col className="m-r-20">
          <LotteryTable
            items={southLotteies}
            title={t("lottery_page.titleSouthTable")}
          />
        </Col>
        <Col className="lot-central-table">
          <LotteryTable
            items={middleLotteies}
            title={t("lottery_page.titleMiddleTable")}
          />
        </Col>
      </Row>
      {digitalLottery && (
        <Row className="bg-white p-l-20">
          <LotteryDigitalTable {...digitalLottery} />
        </Row>
      )}
    </>
  );
};

export default LotterySection;
