import { Modal } from "antd";
import { useState } from "react";

const ConfirmDialog = (props) => {
  const { onDialogResult, icon, title, okText } = props;
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    onDialogResult({
      isSuccess: true,
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    onDialogResult({
      isSuccess: false,
    });
    setIsModalOpen(false);
  };

  return (
    <Modal
      icon={icon}
      title={title}
      open={isModalOpen}
      okText={okText}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.children}
    </Modal>
  );
};

export default ConfirmDialog;
