import { isEmpty } from "lodash";
import Axios from "./axios";
import { isDate, format } from "date-fns";

const MAX_SIZE = 2000000000;

export const getUserTickets = (date, pageIndex, pageSize) => {
  return getInternalUserTickets(
    undefined,
    undefined,
    date,
    pageIndex,
    pageSize
  );
};

export const getUserTicketsByRangeDate = (startDate, date) => {
  return getInternalUserTickets(undefined, startDate, date, 1, MAX_SIZE);
};

export const getInternalUserTickets = (
  country,
  startDate,
  date,
  pageIndex,
  pageSize
) => {
  let queryParams = `pageIndex=${pageIndex}&pageSize=${pageSize}`;
  if (date != null && isDate(date))
    queryParams = `${queryParams}&date=${format(date, "yyyy-MM-dd")}`;

  if (startDate !== undefined && startDate != null && isDate(startDate))
    queryParams = `${queryParams}&startDate=${format(startDate, "yyyy-MM-dd")}`;

  if (country !== undefined && country !== null)
    queryParams = `${queryParams}&countryNormalize=${country}`;

  return Axios({
    withCredentials: true,
    url: `/news/usertickets/me?${queryParams}`,
    method: "GET",
  });
};

export const getUserTicketsOfMe = (
  pageIndex,
  pageSize,
  sort
) => {
  let queryParams = `pageIndex=${pageIndex}&pageSize=${pageSize}`;
  if (sort !== undefined || !isEmpty(sort))
    queryParams += `&sorts={"key": "${sort.key}", "criteria":"${sort.value}"}`
  else
    queryParams += `&sorts={"key": "date", "criteria":"desc"}`

  return Axios({
    withCredentials: true,
    url: `/news/usertickets/ofme?${queryParams}`,
    method: "GET",
  });
};

export const getUserTicketsByCountry = (date, country) => {
  return getInternalUserTickets(country, undefined, date, 1, MAX_SIZE);
};

export const createUserTicket = ({
  email,
  ticketNumber,
  date,
  countryNormalize,
  quantity,
}) =>
  Axios({
    withCredentials: true,
    url: `/news/usertickets`,
    method: "POST",
    data: {
      email,
      ticketNumber,
      date,
      countryNormalize,
      quantity,
    },
  });

export const deleteUserTicket = (id) =>
  Axios({
    withCredentials: true,
    url: `/news/usertickets/${id}`,
    method: "DELETE",
  });


export const checkUserTicketById = (id) =>
  Axios({
    withCredentials: true,
    url: `/news/usertickets/${id}/check`,
    method: "GET",
  });
