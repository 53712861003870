import { Collapse, Typography, Modal, Select, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  QqOutlined,
  PlusCircleOutlined,
  UsergroupAddOutlined,
  StarOutlined,
} from "@ant-design/icons";
import {
  getOnlineUsers,
  getConversationByMe,
  getConversations,
  enrollGroup,
} from "../../services/newsService";
import { some } from "lodash";
import { useGlobalContext } from "../../hooks/useGlobalContext.ts";
import {
  RECEIVE_USER_STATE,
  NOTIFY_STATE_GROUP,
  ConversationState,
} from "../../Constants/commonConstant";
import { uniqBy } from "lodash";
import { routes } from "../../routes.js";

const { Panel } = Collapse;
const defaultPageSize = 10000000;
const CollapseSideBar = () => {
  const [isOpenGroup, setIsOpenGroup] = useState(false);
  const [users, setUsers] = useState([]);
  const [conversations, setConversations] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cvs, setCvs] = useState([]);
  const [groupId, setGroupId] = useState();
  const [status, setStatus] = useState();
  const { connection, user } = useGlobalContext();

  useEffect(() => {
    user &&
      Promise.all([
        fetchConversations(),
        fetchOnlineUsers(),
        fetchAllConversation(),
      ]);
  }, [connection]);

  useEffect(() => {
    if (connection === undefined) return;

    connection.on(RECEIVE_USER_STATE, (data) => {
      const { id, isOnline } = data;

      if (isOnline) {
        const mapUsers = trainUsers([data]);
        const onlineUsers = [...mapUsers, ...users];
        const uniqueOnlineUsers = uniqBy(onlineUsers, "id");
        setUsers(uniqueOnlineUsers);
        return;
      }
      const filterOfflineUsers = users.filter((s) => s.id !== id);
      setUsers(filterOfflineUsers);
    });

    connection.on(NOTIFY_STATE_GROUP, (data) => {
      const { state, conversation } = data;
      if (state === ConversationState.Leave) {
        const filterCvs = conversations.filter((s) => s.id !== conversation.id);
        setConversations(filterCvs);
        return;
      }

      const mapCvs = trainCvs([conversation]);
      let cvs = [...mapCvs, ...conversations];
      cvs = uniqBy(cvs, "id");
      setConversations(cvs);
    });
  }, [connection, conversations]);

  const fetchAllConversation = () => {
    getConversations(1, defaultPageSize).then((res) => {
      const {
        data: { data },
      } = res;
      setCvs(data);
    });
  };

  const fetchOnlineUsers = () => {
    getOnlineUsers().then((res) => {
      const { data } = res;
      const onlineUsers = trainUsers(data);
      setUsers(onlineUsers);
    });
  };

  const fetchConversations = () => {
    getConversationByMe().then((res) => {
      const { data } = res;
      const cvs = data.map((item) =>
        getItem(item.id, item.name, `/groups/${item.id}`)
      );
      setConversations(cvs);
    });
  };

  const showModal = () => {
    setIsOpenGroup(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await handleEnrollGroup();

    setIsOpenGroup(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setIsOpenGroup(false);
  };

  const handleChange = (value) => {
    const existGroup = some(conversations, ["id", value]);
    if (existGroup) {
      setStatus("error");
      setConfirmLoading(true);
    } else {
      setStatus();
      setConfirmLoading(false);
    }
    setGroupId(value);
  };

  const handleEnrollGroup = () => {
    enrollGroup(groupId).then((res) => {
      const {
        data: { conversationId, conversationName },
      } = res;
      const cvs = getItem(
        conversationId,
        conversationName,
        `/groups/${conversationId}`
      );
      setConversations([cvs, ...conversations]);
    });
  };

  return (
    <>
      <Collapse defaultActiveKey={["1", "2", "3"]} ghost>
        <Panel
          header={
            <h3>
              <strong>Apps</strong>
            </h3>
          }
          key="3"
        >
          <AppCollapse />
        </Panel>
        <Panel
          header={
            <h3>
              <strong>Conversations</strong>
            </h3>
          }
          key="1"
        >
          <PagesCollapse setOpenGroup={showModal} items={conversations} />
        </Panel>
        <Panel
          header={
            <h3>
              <strong>Users</strong>
            </h3>
          }
          key="2"
        >
          <UsersCollapse items={users} styleBtn="text-none btn-link" />
        </Panel>
      </Collapse>
      <Modal
        title="Join Group"
        open={isOpenGroup}
        onOk={handleOk}
        okText="Enroll"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={24}>
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="Converations"
              optionFilterProp="Conversation"
              onChange={handleChange}
              value={groupId}
              status={status}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={cvs}
              suffixIcon={<UsergroupAddOutlined />}
              fieldNames={{
                label: "name",
                value: "id",
              }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const UsersCollapse = ({ items, styleBtn = "text-none btn-link " }) => {
  return (
    <ul type="none">
      {items &&
        items.map((item) => (
          <li className="p-b-15" key={item.id}>
            <QqOutlined />
            &ensp;
            <Link className={styleBtn} to={item.link}>
              {item.name}
            </Link>
          </li>
        ))}
    </ul>
  );
};

const PagesCollapse = ({
  setOpenGroup,
  items,
  styleBtn = "text-none btn-link ",
}) => {
  return (
    <ul type="none">
      <li className="p-b-15" key="0">
        <Typography className="f-mono cursor-p" onClick={setOpenGroup}>
          <PlusCircleOutlined /> Join Group
        </Typography>
      </li>
      {items &&
        items.map((item) => (
          <li className="p-b-15" key={item.id}>
            <Link className={styleBtn} to={item.link}>
              {item.name}
            </Link>
          </li>
        ))}
    </ul>
  );
};

const trainUsers = (items) => {
  return items.map((item) =>
    getItem(item.id, `${item.firstName} ${item.lastName}`, `/users/${item.id}`)
  );
};

const trainCvs = (items) => {
  return items.map((item) => getItem(item.id, item.name, `/groups/${item.id}`));
};

const getItem = (id, name, link) => ({ id, name, link });

const AppCollapse = () => {
  return (
    <ul type="none">
      <li className="p-b-15">
        <StarOutlined />
        &ensp;
        <Link className="text-none btn-link" to={routes.app.lottery.home}>
          Lottery
        </Link>
      </li>
    </ul>
  );
};

export default CollapseSideBar;
