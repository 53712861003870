import { Modal } from "antd";
import { useState } from "react";
import { useFormikContext } from "formik";

const Dialog = (props) => {
  const { onDialogResult, icon, title, okText } = props;
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { values, validateForm, isValid, resetForm } = useFormikContext();

  const handleOk = () => {
    validateForm();
    if (!isValid) return;
    onDialogResult({
      isSuccess: true,
      data: { ...values },
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    resetForm();
    onDialogResult({
      isSuccess: false,
    });
    setIsModalOpen(false);
  };

  return (
    <Modal
      icon={icon}
      title={title}
      open={isModalOpen}
      okText={okText}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !isValid }}
    >
      {props.children}
    </Modal>
  );
};

export default Dialog;
