import { DatePicker, Typography } from "antd";
import { useField } from "formik";
import moment from "moment";

const { Text } = Typography;

const DatePickerField = ({ minDate, name, onChange, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const onHandleChange = (dateMoment, dateString) => {
    if (onChange !== undefined) onChange(dateMoment, dateString);

    if (dateMoment === null) {
      return helpers.setValue(null);
    }
    const date = new Date(dateString);
    helpers.setValue(date);
  };

  const defaultValue =
    field.value === null ? null : moment(field.value, "YYYY-MM-DD");

  const disabledDate = (current) => {
    if (minDate === undefined) return false;
    // Can not select days before today and today
    return current && current < moment(minDate);
  };

  return (
    <div>
      <DatePicker
        disabledDate={disabledDate}
        defaultValue={defaultValue}
        value={defaultValue}
        onChange={onHandleChange}
        onBlur={field.onBlur}
        {...props}
      />
      <div>{meta.error ? <Text type="danger">{meta.error}</Text> : null}</div>
    </div>
  );
};

export default DatePickerField;
