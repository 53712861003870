import Axios from "./axios";

export const queryConversations = (pageIndex, pageSize, filters, sorts) =>
  Axios({
    withCredentials: true,
    url: "/news/conversations",
    method: "GET",
    params: {
      pageIndex,
      pageSize,
      filters,
      sorts,
    },
  });

export const getConversations = (pageIndex, pageSize) =>
  queryConversations(
    pageIndex,
    pageSize,
    undefined,
    '{"key": "name", "criteria":"asc"}'
  );

export const getConversationByMe = () =>
  Axios({
    withCredentials: true,
    url: "/news/conversations/me",
    method: "GET",
  });

export const getConversationById = (id) =>
  Axios({
    withCredentials: true,
    url: `/news/conversations/${id}`,
    method: "GET",
  });

export const enrollGroup = (conversationId) => Axios({
  withCredentials: true,
  url: `/news/groupMembers`,
  method: "POST",
  data: {
    conversationId
  }
})

export const leaveConversation = (conversationId) =>
  Axios({
    withCredentials: true,
    url: `/news/conversations/${conversationId}/leave`,
    method: "DELETE",
    data: {
      conversationId
    }
  })

export const queryUsers = (pageIndex, pageSize, filters, sorts) =>
  Axios({
    withCredentials: true,
    url: "/news/users",
    method: "GET",
    params: {
      pageIndex,
      pageSize,
      filters,
      sorts,
    },
  });

export const getUsers = (pageIndex, pageSize) =>
  queryUsers(pageIndex, pageSize, undefined, undefined);

export const getUserById = (id) =>
  Axios({
    withCredentials: true,
    url: `/news/users/${id}`,
    method: "GET",
  });

export const getOnlineUsers = () =>
  Axios({
    withCredentials: true,
    url: "/news/users/online",
    method: "GET",
  });

export const getMessagesCursor = (conversationId, cursor, pageSize = 5) =>
  Axios({
    withCredentials: true,
    url: "/news/messages",
    method: "GET",
    params: {
      conversationId,
      after: cursor,
      pageSize,
    },
  });


export const sendMessage = (conversationId, content) => Axios({
  withCredentials: true,
  url: `/news/messages`,
  method: "POST",
  data: {
    conversationId, content
  }
})