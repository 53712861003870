import { Row, Col, Typography, Grid } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { leaveConversation } from "../../services/newsService";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../hooks/useGlobalContext.ts";
import {
  NOTIFY_STATE_GROUP,
  ConversationState,
} from "../../Constants/commonConstant";
import useEffectOnce from "../../hooks/useHook";

const { useBreakpoint } = Grid;

function PageContainer({
  conversationId,
  title,
  description,
  children,
  memberCount,
}) {
  const { connection } = useGlobalContext();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const { xs } = screens;
  const isMobile = xs;

  const handlerLeave = () => {
    leaveConversation(conversationId).then(() => {
      navigate("/");
    });
  };

  useEffectOnce(() => {
    if (connection === undefined) return;

    connection.on(NOTIFY_STATE_GROUP, (data) => {
      const {
        state,
        conversation: { id },
      } = data;
      if (state === ConversationState.Leave && id === conversationId)
        navigate("/", { replace: true });
    });
  });

  const rowStyle = isMobile ? "main-color p-fixed w-100" : "main-color";
  return (
    <>
      <Row className={rowStyle} justify="center">
        <Col span={23}>
          <Typography>
            <span className="f-mono m-b-0 title-cvs">{title}</span>
            &ensp;
            <span className="f-mono">({description})</span>
            <LogoutOutlined
              className="ico-sign-out color-wh"
              onClick={handlerLeave}
            />
            <p className="sub-title">{memberCount} members</p>
          </Typography>
        </Col>
      </Row>
      <Row justify="center" className="h-90">
        <Col span={23}>{children}</Col>
      </Row>
    </>
  );
}

export default PageContainer;
