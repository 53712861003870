
export const GROUP_MEMBER_SYNC = 'GroupMemberSync';

export const RECEIVE_USER_STATE = 'ReceiveUserState';

export const RECEIVE_MESSAGE = 'ReceiveMessage'

export const NOTIFY_STATE_GROUP = 'NotifyStateGroup'

export const ConversationState = {
    Leave: 0,
    Join: 1
}