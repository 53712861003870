import React from "react";
import LayoutInner from "./pages/__layout";
import ErrorPage from "./pages/error-page";
import Conversation from "./components/conversation/conversation";
import UserDetail from "./components/user-infor/user-detail";
import { createBrowserRouter } from "react-router-dom";
import Login from "./components/account/login";
import SignUp from "./components/account/signup";
import Lottery from "./components/lottery/lottery";
import DashboardUserTicket from "./components/lottery/userTicket/dashboard";
import ManageUserTicket from "./components/lottery/userTicket/manage";
import ManageLottery from "./components/lottery/lottery/manageLottery";
import DashBoardLottery from "./components/lottery/lottery/index";
import UserTicketGrid from "./components/lottery/userTicket/grid";

const routes = {
  main: {
    home: "/",
    conversation: "/groups/:id",
    userDetail: "/users/:id",
    login: "/login",
    signUp: "/sign-up",
  },
  app: {
    lottery: {
      home: "/homelottery",
      userTicket: "userticket",
      lottery: "lottery",
    },
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutInner />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routes.main.conversation,
        element: <Conversation />,
      },
      {
        path: routes.main.userDetail,
        element: <UserDetail />,
      },
      {
        path: routes.main.login,
        element: <Login />,
      },
      {
        path: routes.main.signUp,
        element: <SignUp />,
      },
    ],
  },
  {
    path: routes.app.lottery.home,
    element: <Lottery />,
    children: [
      {
        path: routes.app.lottery.userTicket,
        element: <DashboardUserTicket />,
      },
      {
        path: `${routes.app.lottery.userTicket}/manage`,
        element: <ManageUserTicket />,
      },
      {
        path: `${routes.app.lottery.lottery}`,
        element: <DashBoardLottery />,
      },
      {
        path: `${routes.app.lottery.lottery}/manage`,
        element: <ManageLottery />,
      },
      {
        path: `${routes.app.lottery.userTicket}/grid`,
        element: <UserTicketGrid />,
      },
    ],
  },
]);

export { router, routes };
