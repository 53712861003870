import { useEffect, useState } from "react";
import { getUserTickets } from "../../../../services/userTicketService";
import MenuNavigate from "../../../../componentsUltils/menudate/MenuNavigate";
import BaseFormik from "../../../../componentsUltils/Formik/BaseFormik";
import * as Yup from "yup";
import { useFormik } from "formik";
import GridSection from "./gridSection";
import { Row, Col } from "antd";
import AnalysisSection from "./analysisSection";
import { useGlobalLotteryContext } from "../../../../hooks/useGlobalLotteryContext.ts";
import { useTranslation } from "react-i18next";

const defaultSize = 1000000000;

const DashboardUserTicket = () => {
  const { t } = useTranslation();
  const { cursorUserTicketDashboard, setCursorUserTicketDashboard } =
    useGlobalLotteryContext();
  const [userTickets, setUserTickets] = useState([]);

  const formik = useFormik({
    initialValues: {
      date: cursorUserTicketDashboard.date,
    },
    validationSchema,
  });

  useEffect(() => {
    if (formik.values.date === null) return;

    setCursorUserTicketDashboard({
      ...cursorUserTicketDashboard,
      date: formik.values.date,
    });
    //   // TODO render
    getUserTickets(formik.values.date, 1, defaultSize).then((res) => {
      const {
        data: { data },
      } = res;
      setUserTickets(data);
    });
  }, [formik.values.date]);

  return (
    <BaseFormik formik={formik}>
      <Row>
        <Col span={24}>
          <MenuNavigate title={t("dashboard_page.title")} id="date" name="date" />
        </Col>
      </Row>
      <AnalysisSection items={userTickets} />
      <GridSection items={userTickets} />
    </BaseFormik>
  );
};

const validationSchema = Yup.object().shape({
  date: Yup.date().typeError("Invalid").required("Date not empty"),
});

export default DashboardUserTicket;
